<template>
  <div class="elv-table-group-rows">
    <!-- v-if="props.params.node?.level === 1 || props.params.node?.level === 2" -->
    <SvgIcon
      v-if="false"
      class="elv-table-group-rows__arrow"
      name="group-arrow"
      width="18"
      height="18"
      :style="{ transform: rotate }"
      @click="onCheckGroupExpanded"
    />
    <div class="elv-table-group-rows-content">
      <template v-if="props.params.value?.tableType === 'accounts' && props.params.node?.level === 1">
        <template v-if="props.params.data.platform?.name">
          <img :src="props.params.data.platform?.logo" :alt="props.params.data.platform?.name" />
          <p>{{ props.params.data.platform?.name }}</p>
        </template>
        <p v-else-if="props.params?.data?.counterparty?.isUnnamed || props.params?.data?.counterparty?.name">
          {{ props.params?.data?.counterparty?.isUnnamed ? 'Uncategorized' : props.params?.data?.counterparty?.name }}
        </p>
        <p v-else-if="props.params?.data?.auxiliaryItem?.isUnnamed || props.params?.data?.auxiliaryItem?.value">
          {{
            props.params?.data?.auxiliaryItem?.isUnnamed ? 'Uncategorized' : props.params?.data?.auxiliaryItem?.value
          }}
        </p>
      </template>
      <template v-else-if="props.params.value?.tableType === 'derivative' && props.params.node?.level === 1">
        <template v-if="props.params?.data?.entityAccount?.name">
          <img
            :src="props.params.data?.entityAccount.platform?.logo"
            :alt="props.params.data?.entityAccount.platform?.name"
            style="margin-right: 6px"
          />
          {{ props.params?.data?.entityAccount?.name }}
        </template>
        <p v-else-if="props.params.data.derivativeType">
          {{ t(`common.${props.params.data.derivativeType.toLowerCase()}s`) }}
        </p>
        <p v-else-if="props.params?.data?.auxiliaryItem?.isUnnamed || props.params?.data?.auxiliaryItem?.value">
          {{
            props.params?.data?.auxiliaryItem?.isUnnamed ? 'Uncategorized' : props.params?.data?.auxiliaryItem?.value
          }}
        </p>
      </template>
      <template v-else-if="props.params.value?.tableType === 'deFiPosition' && props.params.node?.level === 0">
        <template v-if="props.params.data?.entityAccount?.platform?.name || props.params.data?.name">
          <div class="elv-table-group-rows-content__label has-logo">
            <img
              :src="props.params.data?.entityAccount?.platform?.logo"
              :alt="props.params.data?.entityAccount.platform?.name"
            />{{ props.params.data?.name }}
          </div>
          <div class="elv-table-group-rows-content__total">
            {{ props.params.data?.list?.length }}
          </div>
        </template>
      </template>
      <template v-else-if="props.params.value?.tableType !== 'holdings'">
        <div class="elv-table-group-rows-content__label">
          {{ formatLanguageContent(props.params.node?.key) }}
        </div>
        <div class="elv-table-group-rows-content__total">
          {{
            props.params.data?.parentAccountName
              ? props.params.data?.parentAccountName
              : props.params.data?.addressCount
                ? props.params.data?.addressCount
                : (props.params.data?.childrenTotal ?? props.params.data?.list?.length)
          }}
        </div>
      </template>
      <template v-else>
        <img :src="currencyData?.logo" :alt="currencyData?.name" />
        <p>{{ currencyData?.name }}</p>
        <span>{{ currencyData?.showSymbol }}</span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { formatLanguageContent } from '@/lib/utils'
import { useEntityStore } from '@/stores/modules/entity'

const props: any = defineProps<{
  params: any
}>()

const { t } = useI18n()
const entityStore = useEntityStore()

const rotate = ref('rotate(0deg)')

const currencyData: any = computed(() => {
  if (props.params.value?.tableType !== 'holdings') return {}
  if (props.params.value?.underlyingCurrency) return props.params.value?.underlyingCurrency
  return find(entityStore.currencyList, { symbol: props.params.node?.key })
})

const onCheckGroupExpanded = () => {
  rotate.value = props.params?.node.expanded ? 'rotate(0deg)' : 'rotate(90deg)'
  props.params?.node.setExpanded(!props.params?.node.expanded)
}

const contentPaddingLeft = computed(() => {
  return props.params.node?.level < 2 ? '0px' : '9px'
})

const imageMarginRight = computed(() => {
  return props.params.value?.tableType === 'accounts' ? '6px' : '8px'
})

watch(
  () => props.params?.node.expanded,
  () => {
    rotate.value = props.params?.node.expanded ? 'rotate(90deg)' : 'rotate(0deg)'
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
:deep(.elv-table-group-rows__arrow) {
  overflow: inherit;
  height: 42px;
  display: flex;
  margin-right: 7px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

:deep(.elv-table-group-rows) {
  display: flex;
}

:deep(.elv-table-group-rows-content) {
  display: flex;
  align-items: center;
  height: 42px;
  padding-left: v-bind('contentPaddingLeft');

  img {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
  }

  p {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: v-bind('imageMarginRight');
    margin-right: 4px;
  }

  span {
    color: #838d95;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

:deep(.elv-table-group-rows-content__label) {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 12px;
  height: 24px;
  background: $elv-color-FFE5BE;
  border: 1px solid $elv-color-FFD89E;
  border-radius: 2px;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: $elv-color-0E1420;

  &.has-logo {
    gap: 4px;

    img {
      height: 16px;
      width: 16px;
    }
  }
}

:deep(.elv-table-group-rows-content__total) {
  font-family: 'Barlow';
  // margin-top: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: $elv-color-838d95;
  height: 24px;
  margin-left: 10px;
}
</style>
